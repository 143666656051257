<template>
  <div class="jobs">
    <div class="jobs-head-wrapper">
      <div class="jobs-header">
        <Header :appMenuStatusUpdate="appMenuStatusUpdate" />
      </div>
      <div id="gdpvcareer" style="margin-top: 100px"></div>
      <div class="subscribe-bar">
        <SubscribeBar :showSusbscribeBar="showSusbscribeBar" :closeSubscribeBar="closeSubscribeBar" />
      </div>
    </div>
    <div class="jobs-footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import { JOBS_SITE_URL } from '@/utils/constants'

function initJobsSite() {
  // jobs page iFrame snippet
  ;(function(d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) return
    js = d.createElement(s)
    js.id = id
    js.src = `${JOBS_SITE_URL}/js/iframe_handler.js`
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'jobs-js')
  triggerWidget()
}
import { Header, SubscribeBar, Footer } from '@/components'
export default {
  components: {
    Header,
    SubscribeBar,
    Footer
  },
  data() {
    return {
      showSusbscribeBar: true
    }
  },
  methods: {
    closeSubscribeBar() {
      this.showSusbscribeBar = false
    },
    appMenuStatusUpdate(updatedStatus) {
      this.showSusbscribeBar = updatedStatus
    }
  },
  mounted() {
    initJobsSite()
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/media-query-mixins';
.jobs-head-wrapper {
  min-height: calc(80vh);
}
.jobs-header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: $primary-white;
}
#gdpvcareer {
  @include ipad-10-portrait {
    margin-top: 90px !important;
  }
  @include ipad-portrait {
    margin-top: 90px !important;
  }
}
</style>
